<template>
  <div>
    <BlackBoxToolCard :card-name="tr('Clinical Timeline')" :payload="payload"></BlackBoxToolCard>
  </div>
</template>

<script setup>
import BlackBoxToolCard from '../BlackBoxToolCard/BlackBoxToolCard.vue'
import { clinicalTimelineBaseUrl } from '@/settings'
const tr = (x) => x // inject('tr')

const payload = {
  data: {
    neutrophils: '',
    platelet: '',
    wbc: '',
  },
  url: {
    baseUrl: clinicalTimelineBaseUrl,
    method: 'GET',
    queryParams: {
      birthdate: { key: 'birthdate', required: true },
      currentThemeName: { key: 'ux2_theme', required: true },
      family: { key: 'family', required: true },
      given: { key: 'given', required: true },
      language: { key: 'language', required: true },
      neutrophils: { key: 'neutrophils', required: true },
      patientId: { key: 'MRN', required: true },
      platelet: { key: 'platelet', required: true },
      toolId: { key: 'toolId', required: true },
      wbc: { key: 'wbc', required: true },
    },
  },
}
</script>

<style lang="scss" scoped></style>
